<template>
  <div class="">
    <v-autocomplete
      dense=""
      v-model="selectedProduct"
      prepend-inner-icon="mdi-magnify"
      class="pa-0 search-bar"
      rounded=""
      :items="products_references_desginations"
      :filter="customFilter"
      filled
      color="blue-grey lighten-2"
      label="Recherche"
      single-line=""
      return-object=""
      clearable=""
    >
      <template v-slot:item="data"> 
        <template>
          <v-list-item-content style=" overflow: hidden">
            <v-list-item-title v-html="data.item.designation"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.reference"></v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <template slot="selection" slot-scope="data">
        <!-- HTML that describe how select should render selected items -->
        {{ data.item.designation }} - {{ data.item.reference }}
      </template>
    </v-autocomplete>
  </div> 
</template>
<script>
import {mapGetters, mapMutations} from 'vuex'
//import DetailsProductComponent from '../components/DetailsProductComponent'
export default {
  data: () => ({
    selectedProduct: null, 
  }),
  components: {
    //DetailsProductComponent
  },
  watch: {
    selectedProduct(prod) {
      console.log(prod)
      if(prod != null) {
        this.$emit('selectedProductFromSearchBar', prod)
      }
    }
  },
  methods: {
    ...mapMutations([]),
    toProductDetailsPage(product){
      console.log(product)
    },
    customFilter (item, queryText) {
      const textOne = item.designation.toLowerCase()
      const textTwo = item.reference.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 
    },
  },
  computed: {
    ...mapGetters(['products_references_desginations']),
  },

  mounted () {
  },
}
</script>

<style>
  .rounded-corners {
    border-radius: 25px;
  }
  .search-bar {
    width: 400px;
  }
  .v-menu__content {
    width: 150px !important;
  }
</style>