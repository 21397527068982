<template>
  <v-card class="pa-12">
    <AutocompleteSearch @selectedProductFromSearchBar="gotSelectedProductFromSearchBar"/>
    <table class="minimalistBlackDialog">
      <thead>
        <tr>
          <th style="min-width:170px">Actions</th>
          <th style="width:100px">Référence</th>
          <th style="min-width:130px">Code Barre</th>
          <th style="min-width:250px;">Désignation</th>
          <th>Prix A</th>
          <th>Prix Promo</th>
          <th>Unité Vente</th>
          <th>S_Colisage</th>
          <th>Colisage</th>
          <th>Dimensions</th>
          <th>Matiere</th>
          <th>Marque</th>
          <th>Emplacement</th>
          <th>Couleurs</th>
          <th>Type Emballage</th>
          <th>Reconditionné</th>
          <th>Description</th>
          
        </tr>
      </thead>
      <tbody>
        <tr v-for="(product,i) of products" :key="i">
          <td>
            <v-btn fab text @click="fetchProductsByReference({reference: product.reference})">
              <ArrowDownBold/>
            </v-btn>
            <v-btn fab text @click="restoreProduct(product)">
              <BackupRestore/>
            </v-btn>
          </td>
          <td>{{product.reference}}</td>
          <td>{{product.codeBarre}}</td>
          <td>
            <span style="width:100%">{{ product.designation }}</span>
          </td>
          <td>
            <span>{{ product.prixA }}</span>
          </td>
          <td>
            <span>{{ product.prixPromo }}</span>
          </td>
          <td>
            <span>{{ product.uniteDeVente }}</span>
          </td>
          <td>
            <span>{{ product.sous_colisage }}</span>
          </td>
          <td>
            <span>{{ product.colisage }}</span>
          </td>
          <td>
            <span>{{ product.dimensions }}</span>
          </td>
          <td>
            <span>{{ product.matiere }}</span>
          </td>
          <td>
            <span>{{ product.marque }}</span>
          </td>
          <td>
            <span>{{ product.emplacement }}</span>
          </td>
          <td>
            <span>{{ product.couleur }}</span>
          </td>
          <td>
            <span>{{ product.type_emballage }}</span>
          </td>
          <td>
            <span>{{ product.reconditionne }}</span>
          </td>
          <td>
            <span>{{ product.description }}</span>
          </td>
          
        </tr>
      </tbody>
    </table>
  </v-card>
</template>

<script>
import axios from 'axios'
import { mapMutations, mapActions, mapGetters } from "vuex";
import AutocompleteSearch from '../components/AutocompleteSearch'
import ArrowDownBold from 'vue-material-design-icons/ArrowDownBold.vue';
import BackupRestore from 'vue-material-design-icons/BackupRestore.vue';
export default {
  props: ["product"],
  data: () => ({
    products: [],
  }),
  components: {
    AutocompleteSearch,
    ArrowDownBold,
    BackupRestore
  },
  methods: {
    ...mapMutations(["setProductDetailsDialog"]),
    ...mapActions(["fetchProductsByReference"]),
    /*changed(product) {
      console.log(product.designation);
    },*/
    closeDialog() {
      this.setProductDetailsDialog(false);
    },
    async gotSelectedProductFromSearchBar(prod) {
      console.log('from parent ->', prod)
      try {
        let prodByRefRes = await axios.get(`${this.apiURL}/products/product/reference/${prod.reference}`)
        this.products.push(prodByRefRes.data)
      } catch(err) {
        console.log(err)
        
      }
      
    },
    restoreProduct(product) {
      this.fetchProductsByReference({...product, doRestore: true})
    }
  },
  computed: {
    ...mapGetters(['apiURL'])
  },
  mounted() {
    if(this.product != {}) {
      this.products.push(this.product);
    }
    
  },
  beforeDestroy() {
    //console.log("before destroy prod details dialog");
    //this.product = null
  },
};
</script>
<style scoped>
input {
  /*border-bottom: 0.5mm solid #00000033;
  transition: all 0.5s;
  height: 100px;*/
}
.v-card {
  overflow: auto;
}
table.minimalistBlackDialog {
  border: 1px solid #000000;
  text-align: left;
  border-collapse: collapse;
}
table.minimalistBlackDialog td,
table.minimalistBlackDialog th {
  border: 0.5mm solid #000000;
  /*padding: 5px 4px;*/
}
table.minimalistBlackDialog tbody td {
  font-size: 13px;
}
table.minimalistBlackDialog thead {
  border-bottom: 0.5mm solid #000000;
}
table.minimalistBlackDialog thead th {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  text-align: left;
}
table.minimalistBlackDialog tfoot td {
  border: 0.5mm solid black;
}
</style>